import axios from "../plugins/axios"

export const getIncomingLists = async()=>{
    const response = await axios.get("/api/incomingLists/all");
    return response;
}

export const addNewIncomingLists = async(payload)=>{
    const response = await axios.post("/api/incomingLists/create",payload);
    return response;
}

export const addIncomingProduct = async(payload)=>{
    const response = await axios.post("/api/incomingLists/product/add",payload);
    return response;
}

export const getSingleIncomingLists = async(id)=>{
    const response = await axios.get("/api/incomingLists/one/"+id);
    return response;
}

export const submitIncomingList = async(payload)=>{
    const response = await axios.post("/api/incomingLists/submit",payload);
    return response;
}

export const approveIncomingList = async(payload)=>{
    const response = await axios.post("/api/incomingLists/approve",payload);
    return response;
}

export const confirmImportIncomingList = async(payload)=>{
    const response = await axios.post("/api/incomingLists/import-admin/approve-and-submit-for-finance-approval",payload);
    return response;
}

// for outgoing lists

export const getOutgoingLists = async()=>{
    const response = await axios.get("/api/outgoingLists/all");
    return response;
}

export const addNewOutgoingLists = async(payload)=>{
    const response = await axios.post("/api/outgoingLists/create",payload);
    return response;
}

export const addOutgoingProduct = async(payload)=>{
    const response = await axios.post("/api/outgoingLists/product/add",payload);
    console.log(payload,"payload");
    console.log(response,"response");
    return response;
}

export const getSingleOutgoingLists = async(id)=>{
    const response = await axios.get("/api/outgoingLists/one/"+id);
    return response;
}

export const submitOutgoingList = async(payload)=>{
    const response = await axios.post("/api/outgoingLists/submit-for-sales-supervisor-approval",payload);
    return response;
}

//for sales submitting list
export const approveOutgoingList = async(payload)=>{
    const response = await axios.post("/api/outgoingLists/approve",payload);
    return response;
}

export const confirmDelivery = async(payload)=>{
    const response = await axios.post("/api/outgoingLists/delivery/confirm",payload);
    return response;
}

export const confirmSuperVisorDelivery = async(payload)=>{
    const response = await axios.post("/api/outgoingLists/supervisor/approve-and-submit-for-finance-approval",payload);
    return response;
}

export const updateIncomingProductList = async(payload)=>{
    const response = await axios.post("/api/incomingLists/product/update",payload);
    return response;
}

export const deleteIncomingProductList = async(payload)=>{
    const response = await axios.post("/api/incomingLists/product/remove",payload);
    return response;
}

export const updateOutgoingProductList = async(payload)=>{
    const response = await axios.post("/api/outgoingLists/product/update",payload);
    return response;
}

export const deleteOutgoingProductList = async(payload)=>{
    const response = await axios.post("/api/outgoingLists/product/remove",payload);
    return response;
}