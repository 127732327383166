import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({chartData}) => {
  

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default BarChart;