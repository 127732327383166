import { getDashboardAnalyticsData } from "../../store/inventoryReducer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Row, Col, Statistic } from "antd";
import { Select } from "antd";

import PieChart from "../../components/base/pieChart";
import LineChart from "../../components/base/lineChart";
import BarChart from "../../components/base/barChart";

import {
  outgoingRankingReportReducer,
  customerRankingFrequencyReducer,
  rankingProductsReportReducer,
} from "../../store/report";
import {
  convertInventoryDataToChartFormat,
  convertToChartData,
  convertToLineChartData,
} from "../../utils/customFunctions";
const { Option } = Select;
const App = () => {
  const { statData, isLoading } = useSelector((state) => state.inventory);
  const { customerRanking, outgoingRanking, rankingProducts } = useSelector(
    (state) => state.report
  );
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    sortBy: '',
    sort: '',
    transaction_type: '',
    limit: 5,
  });
  useEffect(() => {
    dispatch(outgoingRankingReportReducer());
    dispatch(customerRankingFrequencyReducer());
    dispatch(rankingProductsReportReducer());
    dispatch(getDashboardAnalyticsData());
  }, [dispatch]);

  useEffect(() => {
    dispatch(rankingProductsReportReducer(filters));
  }, [filters,dispatch]); 
  // Handle changes for each select field
  const handleSortByChange = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, sortBy: value }));
  };

  const handleSortChange = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, sort: value }));
  };

  const handleTransactionTypeChange = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, transactionType: value }));
  };

  const handleLimitChange = (value) => {
    setFilters((prevFilters) => ({ ...prevFilters, limit: value }));
  };

  return (
    <div>
      
      <Row gutter={8}>
        <Col lg={8} sm={24}>
          <Card style={{ marginBottom: "10px" }}>
            <Statistic
              valueStyle={{ fontWeight: "bolder", fontSize: "30px" }}
              loading={isLoading}
              title="Users"
              value={statData?.userCount}
              style={{ padding: "10px" }}
            />
          </Card>
          <Card>
            <Statistic
              valueStyle={{ fontWeight: "bolder", fontSize: "30px" }}
              loading={isLoading}
              title="Products"
              value={statData?.productCount}
              style={{ padding: "10px" }}
            />
          </Card>
        </Col>
        <Col lg={8} sm={24}>
          <Card style={{ marginBottom: "10px" }}>
            <Statistic
              loading={isLoading}
              valueStyle={{ fontWeight: "bolder", fontSize: "30px" }}
              className=""
              style={{ padding: "10px" }}
              title=" Inventory "
              value={statData?.inventoryCount}
            />
          </Card>
          <Card>
            <Statistic
              loading={isLoading}
              valueStyle={{ fontWeight: "bolder", fontSize: "30px" }}
              style={{ padding: "10px" }}
              title="Customers "
              className=""
              value={statData?.customerCount}
            />
          </Card>
        </Col>
        <Col lg={8} sm={24}>
          <Card style={{ marginBottom: "10px" }}>
            <Statistic
              loading={isLoading}
              valueStyle={{ fontWeight: "bolder", fontSize: "30px" }}
              title="Warehouses"
              value={statData?.warehouseCount}
              style={{ padding: "10px" }}
            />
            {/* <label>Cup</label> */}
          </Card>
          <Card>
            <Statistic
              loading={isLoading}
              valueStyle={{ fontWeight: "bolder", fontSize: "30px" }}
              style={{ padding: "10px" }}
              title="Product Categories"
              value={statData?.categoryCount}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={10} style={{ marginTop: "50px" }}>
        <Col lg={12} sm={24}>
          <Row
            gutter={16}
            justify="space-between"
            align="middle"
            style={{ marginBottom: "20px" }}
          >
            <Col span={6}>
              <Select
                onChange={handleSortByChange}
                placeholder="Sort By"
                style={{ width: "100%" }}
              >
                <Option value="frequency">Frequency</Option>
                <Option value="totalQuantity">Quantity</Option>
                <Option value="all">All</Option>
              </Select>
            </Col>
            <Col span={6}>
              <Select
                onChange={handleSortChange}
                placeholder="Sort"
                style={{ width: "100%" }}
              >
                <Option value="LowestFirst">Lowest First</Option>
                <Option value="highestFirst">Highest First</Option>
                <Option value="all">All</Option>
              </Select>
            </Col>
            <Col span={6}>
              <Select
                onChange={handleTransactionTypeChange}
                placeholder="Transaction Type"
                style={{ width: "100%" }}
              >
                <Option value="Outgoing">Outgoing</Option>
                <Option value="Incoming">Incoming</Option>
                <Option value="all">All</Option>
              </Select>
            </Col>
            <Col span={6}>
              <Select
                onChange={handleLimitChange}
                placeholder="Limit"
                style={{ width: "100%" }}
              >
                <Option value={5}>5</Option>
                <Option value={10}>10</Option>
                <Option value={20}>20</Option>
                <Option value={50}>50</Option>
              </Select>
            </Col>
          </Row>
          <h2 style={{ textAlign: "left" }}>Products Top Selling</h2>
          <PieChart chartData={convertToChartData(rankingProducts)} />
        </Col>
        <Col lg={12} sm={24}>
          <h2 style={{ textAlign: "left" }}>Top Customers</h2>
          <LineChart chartData={convertToLineChartData(customerRanking)} />
        </Col>
        <Col lg={24} sm={24}>
          <h2 style={{ textAlign: "left" }}>Transactions</h2>
          <BarChart chartData={convertInventoryDataToChartFormat(outgoingRanking)} />
        </Col>
      </Row>
    </div>
  );
};

export default App;
