import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getIncomingLists,
  addNewIncomingLists,
  getSingleIncomingLists,
  addIncomingProduct,
  submitIncomingList,
  approveIncomingList,
  confirmImportIncomingList,
  updateIncomingProductList,
  deleteIncomingProductList

} from "../services/incomingLists";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  isError: false,
  isSuccess: false,
  message: "",
  incomingList: {},
  incomingLists: [],
};
export const fetchIncomingLists = createAsyncThunk(
  "incoming/fetch",
  async (thunkAPI) => {
    try {
      return await getIncomingLists();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createIncomingList = createAsyncThunk(
  "incoming/create",
  async (payload, thunkAPI) => {
    try {
      return await addNewIncomingLists(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createIncomingProduct = createAsyncThunk(
  "incoming/product/add",
  async (payload, thunkAPI) => {
    try {
      console.log(payload,"inside reducer")
      return await addIncomingProduct(payload);
    } catch (error) {
      console.log(error,"error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveIncomingList = createAsyncThunk(
  "incoming/product/submit",
  async (payload, thunkAPI) => {
    try {
      return await submitIncomingList(payload);
    } catch (error) {
      console.log(error,"error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmIncomingList = createAsyncThunk(
  "incoming/product/approve",
  async (payload, thunkAPI) => {
    try {
      return await approveIncomingList(payload);
    } catch (error) {
      console.log(error,"error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateIncomingProductListReducer = createAsyncThunk(
  "incoming/product/update/list",
  async (payload, thunkAPI) => {
    try {
      return await updateIncomingProductList(payload);
    } catch (error) {
      console.log(error,"error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const removeIncomingProductListReducer = createAsyncThunk(
  "incoming/product/remove/list",
  async (payload, thunkAPI) => {
    try {
      return await deleteIncomingProductList(payload);
    } catch (error) {
      console.log(error,"error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const confirmImportAdminIncomingList = createAsyncThunk(
  "incoming/product/import/confirm",
  async (payload, thunkAPI) => {
    try {
      return await confirmImportIncomingList(payload);
    } catch (error) {
      console.log(error,"error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchSingleIncomingList = createAsyncThunk(
  "incoming/single",
  async (id, thunkAPI) => {
    try {
      return await getSingleIncomingLists(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const incomingListsSlice = createSlice({
  name: "incomingLists",
  initialState,
  reducers: {
    getAllDevices: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncomingLists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchIncomingLists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        console.log(action.payload?.data?.incoming_list_all, "inside reducer");
        state.incomingLists = action.payload?.data?.incoming_list_all;
      })
      .addCase(fetchIncomingLists.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(createIncomingList.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createIncomingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Incoming List Created Successfully");
      })
      .addCase(createIncomingList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(fetchSingleIncomingList.pending, (state, action) => {
        state.isLoading = true;
      }).addCase(fetchSingleIncomingList.fulfilled, (state, action)=>{
        state.isLoading = false;
        state.incomingList = action.payload?.data?.incoming_list[0];
        state.message = action.payload?.message;
      }).addCase(fetchSingleIncomingList.rejected,(state,action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(createIncomingProduct.pending, (state, action) => {
        state.isLoading = true;
      }).addCase(createIncomingProduct.fulfilled, (state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Product Added Successfully");
      }).addCase(createIncomingProduct.rejected,(state,action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(saveIncomingList.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(saveIncomingList.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Incoming List Submitted Successfully");
      }).addCase(saveIncomingList.rejected,(state,action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(confirmIncomingList.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(confirmIncomingList.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Incoming List Approved Successfully");
      }).addCase(confirmIncomingList.rejected,(state,action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(confirmImportAdminIncomingList.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(confirmImportAdminIncomingList.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Import Admin Incoming List Confirmed Successfully");
      }).addCase(confirmImportAdminIncomingList.rejected,(state,action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(updateIncomingProductListReducer.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(updateIncomingProductListReducer.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Incoming List Updated Successfully");
      }).addCase(updateIncomingProductListReducer.rejected,(state,action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(removeIncomingProductListReducer.pending,(state,action) => {
        state.isLoading = true;
      }).addCase(removeIncomingProductListReducer.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Incoming List Removed Successfully");
      }).addCase(removeIncomingProductListReducer.rejected,(state,action)=>{
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
  },
});

export const { getAllDevices } = incomingListsSlice.actions;
export const isLoading = (state) => state.incoming.isLoading;
export const isError = (state) => state.incoming.isError;
export const isSuccess = (state) => state.incoming.isSuccess;
export const message = (state) => state.incoming.message;
export const incomingLists = (state) => state.incoming.incomingLists;
export const incomingList = (state) => state.incoming.incomingList;

export default incomingListsSlice.reducer;
