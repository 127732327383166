import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { assetValuationReport,assetValuationReportExcel,customerRankingFrequency,outgoingRankingReport,rankingProductsReport } from "../services/report";
import { toast } from "react-toastify";

const intialState = {
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
  excelFile: null,
  customerRanking: [],
  outgoingRanking: [],
  rankingProducts: [],
  report: {},
  reports: [],
};

export const assetValuationReportReducer = createAsyncThunk(
  "report/assetValuationReport",
  async (payload, thunkApii) => {
    try {
      return await assetValuationReport(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApii.rejectWithValue(message);
    }
  }
);

export const customerRankingFrequencyReducer = createAsyncThunk(
  "report/customer/frequencies",
  async (payload, thunkApii) => {
    try {
      return await customerRankingFrequency(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApii.rejectWithValue(message);
    }
  }
);

export const assetValuationReportExcelReducer = createAsyncThunk(
  "report/asset/valuation_reports",
  async (payload, thunkApii) => {
    try {
      return await assetValuationReportExcel(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApii.rejectWithValue(message);
    }
  }
);

export const outgoingRankingReportReducer = createAsyncThunk(
    "report/outgoing/rank/reports",
    async (payload, thunkApii) => {
      try {
        return await outgoingRankingReport(payload);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkApii.rejectWithValue(message);
      }
    }
  );

  export const rankingProductsReportReducer = createAsyncThunk(
    "report/products/rank/frequency",
    async (payload, thunkApii) => {
      try {
        return await rankingProductsReport(payload);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkApii.rejectWithValue(message);
      }
    }
  );

const reportSlice = createSlice({
  name: "report",
  initialState: intialState,
  reducers: {
    logoutInternal: (state) => {
      state.isLoggedIn = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(assetValuationReportReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assetValuationReportReducer.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.isError = false;
        state.isLoading = false;
        state.isLoggedIn = true;
        state.reports = action.payload.data;
      })
      .addCase(assetValuationReportReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(assetValuationReportExcelReducer.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(assetValuationReportExcelReducer.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isSuccess = true;
        state.excelFile = action.payload;
      }).addCase(customerRankingFrequencyReducer.pending,(state,action)=>{ 
        state.isLoading = true;
      }).addCase(customerRankingFrequencyReducer.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.customerRanking = action.payload.data;
      }).addCase(customerRankingFrequencyReducer.rejected,(state,action)=>{
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(outgoingRankingReportReducer.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(outgoingRankingReportReducer.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isSuccess = true;
        state.outgoingRanking = action.payload.data;
      }).addCase(outgoingRankingReportReducer.rejected,(state,action)=>{
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      }).addCase(rankingProductsReportReducer.pending,(state,action)=>{
        state.isLoading = true;
      }).addCase(rankingProductsReportReducer.fulfilled,(state,action)=>{
        state.isLoading = false;
        state.isSuccess = true;
        state.rankingProducts = action.payload.data;
      }).addCase(rankingProductsReportReducer.rejected,(state,action)=>{
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
  },
});

export const isSuccess = (state) => state.report.isSuccess;
export const isError = (state) => state.report.isError;
export const isLoading = (state) => state.report.isLoading;
export const excelFile = (state) => state.report.excelFile;
export const report = (state) => state.report.report;
export const customerRanking = (state) => state.report.customerRanking;
export const outgoingRanking = (state) => state.report.outgoingRanking;
export const rankingProducts = (state) => state.report.rankingProducts;
export const reports = (state) => state.report.reports;

export default reportSlice.reducer;

export const categories = (state) => state.category.categories;
