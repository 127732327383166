import React, { useEffect, useState } from "react";
import { Button, Row, Image, Col, Form, Input, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProductsSearchReducer } from "../../store/inventoryReducer";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  createIncomingProduct,
  fetchSingleIncomingList,
  removeIncomingProductListReducer,
  saveIncomingList,
  updateIncomingProductListReducer,
} from "../../store/incomingListReducer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TableA from "../../components/tables/table";
import TextArea from "antd/lib/input/TextArea";

const Incominginventory = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [quantityData, setQuantityData] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [productId, setProductId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [productQuery] = useState("");
  const [setWarhousename] = useState("");
  const { inventories } = useSelector((state) => state.inventory);
  const [activeTab, setActiveTab] = useState("draft");
  const [openIncomingModal, setOpenIncomingModal] = useState(false);
  const [submitModal, setSubmitModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const { incomingList } = useSelector((state) => state.incoming);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const [quantityFields, setQuantityFields] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleIncomingList(id));
    dispatch(getActiveWarehouseReducer());
    setQuantityData([]);
    // setWarhousename(crdentialDataForOne?.warehouse);
  }, [id, setWarhousename, dispatch, crdentialDataForOne]);
  const handleProductQuery = (event) => {
    dispatch(
      getProductsSearchReducer({
        query: event.target.value,
      })
    );
  };
  const handleEditIncomingProduct = (record) => {
    setProductId(record?.product?._id);
    setQuantityFields(record.quantity);
    setEditModal(true);
  };

  const handleDeleteIncomingProduct = (record) => {
    dispatch(
      removeIncomingProductListReducer({
        list_id: id,
        product_id: record?.product?._id,
      })
    );
  };
  const editIncomingProduct = () => {
    dispatch(
      updateIncomingProductListReducer({
        list_id: id,
        product_id: productId,
        quantity: quantityFields,
      })
    );
  };
  const handlePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
    setPreviewOpen(true);
  };

  const displayIncomingModal = () => {
    setActiveTab(activeTab === "draft" ? "received" : "draft");
  };
  const Image_url = "https://ethiodererp.com/images/products/";
  const submitIncomingList = () => {
    dispatch(
      saveIncomingList({
        list_id: id,
        remark: remarks,
      })
    );
  };
  const addNewIncomingList = async () => {
    try {
      // Transform the quantityData object into an array
      const arr = Object.entries(quantityData).map(([key, value]) => ({
        name: key,
        value,
      }));

      // Dispatch the createIncomingProduct action and wait for it to complete
      const resultAction = await dispatch(
        createIncomingProduct({
          list_id: id,
          product: productId,
          quantity: arr,
        })
      );

      // Check if the action was fulfilled (successful)
      if (createIncomingProduct.fulfilled.match(resultAction)) {
        // Fetch the updated list after the product has been successfully created
        await dispatch(fetchSingleIncomingList(id));

        // Close the modal and set the active tab
        setOpenIncomingModal(false);
        setActiveTab("draft");
      } else {
        console.error(
          "Failed to create the incoming product:",
          resultAction.payload
        );
        // You might want to handle this case (e.g., show an error message)
      }
    } catch (error) {
      console.error("Error creating incoming product:", error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };

  const handleRemarks = (event) => {
    setRemarks(event.target.value);
  };

  const displayModal = (record) => {
    // setHandleModal(handleModal);
    setProductId(record?._id);
    setOpenIncomingModal(true);
    setProductQuantity(record?.quantity);
  };

  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };

  const handleEditQuantityData = (event) => {
    const { name, value } = event.target;

    // Update the quantityFields state immutably
    setQuantityFields((prevFields) =>
      prevFields.map((field) =>
        field.name === name ? { ...field, value } : field
      )
    );

    // Update the form's field value
    form.setFieldsValue({
      [name]: value,
    });
  };

  const columnsA = [
    {
      title: "Product Name",
      render: (text, record) => <span>{record.name}</span>,
    },
    {
      title: "Product Image",
      render: (text, record) => (
        <span>
          {record.image_extension ? (
            <Image
              width={100}
              src={Image_url + record._id + record.image_extension}
              onClick={() =>
                handlePreview(Image_url + record._id + record.image_extension)
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            "Image unavailable"
          )}
        </span>
      ),
    },
    {
      title: "Quantity",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {record.quantity.map((item) => (
            <li>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <span>
          <Button
            onClick={() => displayModal(record, "OutgoingInventory")}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  const columnsB = [
    {
      title: "Product Name",
      render: (text, record) => <span>{record.product.name}</span>,
    },
    {
      title: "Product Code",
      render: (text, record) => <span>{record.product?.code}</span>,
    },
    {
      title: "Product Image",
      render: (text, record) => (
        <span>
          {record.product.image_extension ? (
            <Image
              width={100}
              src={
                Image_url + record.product._id + record.product.image_extension
              }
              onClick={() =>
                handlePreview(
                  Image_url +
                    record.product._id +
                    record.product.image_extension
                )
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            "Image unavailable"
          )}
        </span>
      ),
    },

    {
      title: "Quantity",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {record.quantity.map((item) => (
            <li>
              {item.value} : {item.name}
            </li>
          ))}
        </span>
      ),
    },
  ];

  const columnsBForAdmin = [
    {
      title: "Product Name",
      render: (text, record) => <span>{record.product.name}</span>,
    },
    {
      title: "Product Code",
      render: (text, record) => <span>{record.product?.code}</span>,
    },
    {
      title: "Product Image",
      render: (text, record) => (
        <span>
          {record.product.image_extension ? (
            <Image
              width={100}
              src={
                Image_url + record.product._id + record.product.image_extension
              }
              onClick={() =>
                handlePreview(
                  Image_url +
                    record.product._id +
                    record.product.image_extension
                )
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            "Image unavailable"
          )}
        </span>
      ),
    },

    {
      title: "Quantity",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {record.quantity.map((item) => (
            <li>
              {item.value} : {item.name}
            </li>
          ))}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <>
          {incomingList?.status === "draft" ? (
            <span>
              <Button
                onClick={() => handleEditIncomingProduct(record)}
                className="mr-2"
                icon={<EditOutlined />}
              ></Button>
              <Button
                onClick={() => handleDeleteIncomingProduct(record)}
                className="mr-2"
                icon={<DeleteOutlined />}
              ></Button>
            </span>
          ) : (
            ""
          )}
        </>
      ),
    },
  ];

  return (
    <div className="ml-4">
      <div className="">
        {activeTab === "draft" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              justifyContent: "space-between",
            }}
          >
            <h2
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              GRN: {incomingList?.grn}
            </h2>
            <h2
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              Status: {incomingList?.status}
            </h2>
            {incomingList?.status === "draft" ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#5856d6",
                    color: "white",
                  }}
                  onClick={() => setSubmitModal(true)}
                  className="mt-2"
                  icon={<PlusCircleOutlined />}
                >
                  Submit List
                </Button>
                <Button
                  onClick={displayIncomingModal}
                  color="green"
                  style={{ backgroundColor: "#5856d6", color: "white" }}
                >
                  Add Product
                </Button>
              </div>
            ) : (
              <p className="">List is already submitted.</p>
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        centered
        onOk={editIncomingProduct}
        onCancel={() => setEditModal(false)}
        title="Edit Incoming Lists"
        visible={editModal}
      >
        <Row gutter={5}>
          <Col xs={24} lg={24}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                width: 300,
              }}
            >
              {quantityFields?.map((item) => (
                <Form.Item
                  className="capitalize"
                  style={{ textTransform: "capitalize" }}
                  label={item.name}
                >
                  <Input
                    placeholder={item.value}
                    key={item._id}
                    name={item.name}
                    value={item.value}
                    onChange={handleEditQuantityData}
                  />
                </Form.Item>
              ))}
            </Form>
          </Col>
        </Row>
      </Modal>
      <Modal
        centered
        onOk={submitIncomingList}
        onCancel={() => setSubmitModal(false)}
        title="Add Remarks"
        visible={submitModal}
      >
        <Form>
          <Form.Item labelAlign="vertical">
            <label style={{ marginBottom: "10px" }}>Remarks</label>
            <TextArea
              onChange={handleRemarks}
              rows={8}
              name="remarks"
              value={remarks}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        onOk={addNewIncomingList}
        onCancel={() => setOpenIncomingModal(false)}
        title="New Incoming Lists"
        visible={openIncomingModal}
      >
        <Row gutter={5}>
          <Col xs={24} lg={24}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                width: 300,
              }}
            >
              {productQuantity?.map((item) => (
                <Form.Item
                  className="capitalize"
                  style={{ textTransform: "capitalize" }}
                  name={item}
                  label={item}
                >
                  <Input
                    name={item}
                    value={item}
                    onChange={handleQuantityData}
                  />
                </Form.Item>
              ))}
              {/* <Form.Item
                className="capitalize"
                name="displayName"
                label="Remarks"
              >
                <Input
                  type="text"
                  name={displayName}
                  value={displayName}
                  onChange={handleDisplayName}
                />
              </Form.Item> */}
              {/* <Form.Item className="capitalize" name="remarks" label="Remarks">
                <Input
                  type="text"
                  name={remarks}
                  value={remarks}
                  onChange={handleRemarks}
                />
              </Form.Item> */}
            </Form>
          </Col>
        </Row>
      </Modal>

      {activeTab === "draft" ? (
        <div>
          <div className="table">
            <TableA
              columnnn={
                crdentialDataForOne?.role === "warehouseAdmin"
                  ? columnsBForAdmin
                  : columnsB
              }
              dataaa={incomingList?.inventory_list}
            />
          </div>
        </div>
      ) : (
        <div>
          <h2 className="text-xl mb-2">Incoming Inventory</h2>
          <Row gutter={20}>
            <Col xs={24} sm={24} lg={8}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item name="productname" label="Product Name">
                  <Input
                    value={productQuery}
                    name="productQuery"
                    onChange={handleProductQuery}
                    placeholder="Search by Product"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <div>
            <TableA columnnn={columnsA} dataaa={inventories} />
          </div>
        </div>
      )}
    </div>
  );
};
export default Incominginventory;
