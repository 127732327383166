import React, { useState, useEffect } from "react";
import "../../assets/styles/greetingComponent.css";

const GreetingComponent = ({ name }) => {
  const [greeting, setGreeting] = useState("");
  const [message, setMessage] = useState("");
  const [timeOfDay, setTimeOfDay] = useState("");

  useEffect(() => {
    updateGreeting();
  }, []);

  const updateGreeting = () => {
    const hour = new Date().getHours();

    if (hour < 12) {
      setGreeting("Good Morning");
      setMessage("Start your day with positive vibes!");
      setTimeOfDay("morning");
    } else if (hour < 18) {
      setGreeting("Good Afternoon");
      setMessage("Keep going strong this afternoon!");
      setTimeOfDay("afternoon");
    } else {
      setGreeting("Good Evening");
      setMessage("Relax and unwind this evening!");
      setTimeOfDay("evening");
    }
  };

  return (
    <div className={`greeting-container ${timeOfDay}`}>
      <div className="greeting-text">
        <h1>
          {greeting}, {name || "Guest"}!
        </h1>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default GreetingComponent;