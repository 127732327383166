import React, { useState } from "react";
import { Table, Pagination } from "antd";
import useWindowSize from "./useWindow";

const App = ({ columnnn, dataaa, isLoadinggg, isInventory }) => {
  const { height, width } = useWindowSize();

  // Calculate available height for the table body
  const tableHeight = height - 300; // Adjust this value based on your layout

  // State for pagination
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20, // Default page size
    total: dataaa?.length || 0, // Total number of records
  });

  // Function to handle pagination changes
  const handleTableChange = (page, pageSize) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
      pageSize,
    }));
  };

  // Slicing the data for pagination
  const paginatedData = dataaa?.slice(
    (pagination.current - 1) * pagination.pageSize,
    pagination.current * pagination.pageSize
  );

  const getRowClassName = (record) => {
    if (!record?.quantity || !record?.product?.buffer_stock?.quantity || !isInventory) {
      return "";
    }

    const bufferQuantity = record.product.buffer_stock.quantity;
    const orderedQuantity = record.quantity[0]?.value;

    if (orderedQuantity !== undefined) {
      if (bufferQuantity >= orderedQuantity) {
        console.log(bufferQuantity, orderedQuantity);
        return "out-of-stock";
      } else {
        return "bg-primary";
      }
    }

    return "";
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 230px)",
      }}
    >
      <div style={{ flexGrow: 1, overflow: "auto" }}>
        <Table
          sticky
          size="large"
          style={{ height: tableHeight + "px" }}
          columns={columnnn}
          dataSource={paginatedData} // Paginated data here
          scroll={{ y: tableHeight, x: width }}
          loading={isLoadinggg}
          pagination={false} // Disabling built-in pagination
          rowClassName={getRowClassName}
        />
      </div>

      <div style={{ flexShrink: 0 }}>
        <Pagination
          style={{ textAlign: "center", padding: "10px 0" }}
          current={pagination.current} // Current page
          pageSize={pagination.pageSize} // Page size
          total={pagination.total} // Total number of records
          onChange={handleTableChange} // Handle pagination changes
          showSizeChanger
          pageSizeOptions={["10", "20", "30", "40"]}
        />
      </div>
    </div>
  );
};

export default App;