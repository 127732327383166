import React from "react";
import Chart from "react-apexcharts";

const PieChart = ({chartData}) => {
 

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width="500" // You can adjust the width
      />
    </div>
  );
};

export default PieChart;