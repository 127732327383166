export function convertToChartData(dataArray) {
    console.log(dataArray);
    return {
      series: dataArray?.map(item => item.quantity), // Change to `item.frequency` if needed
      options: {
        chart: {
          type: "pie",
        },
        labels: dataArray?.map(item => item.productName),
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 300,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 0,
          height: 230,
        },
      },
    };
  }

export function convertToLineChartData(customers) {
    return {
      series: customers?.map(customer => ({
        name: customer.customerName,
        data: [customer.totalTransactions],
        offsetY: 0
      })),
      options: {
        chart: {
          width: "100%",
          height: 350,
          type: "area",
          toolbar: { show: false },
        },
        legend: { show: false },
        dataLabels: { enabled: false },
        stroke: { curve: "smooth" },
        yaxis: {
          labels: {
            style: {
              fontSize: "14px",
              fontWeight: 600,
              colors: ["#8c8c8c"],
            },
          },
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "14px",
              fontWeight: 600,
              colors: Array(customers?.length).fill("#8c8c8c"),
            },
          },
          categories: ["Transaction 1"], // Static label; adjust if adding more transactions over time
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  }

export  const convertInventoryDataToChartFormat = (inventoryData) => {
    // Prepare categories (product names)
    const categories = inventoryData.map(item => item.productName);

    // Prepare outgoing and incoming data
    const outgoingData = inventoryData.map(item => item.quantity * item.frequency); // Total outgoing per product
    const incomingData = inventoryData.map(item => item.quantity); // Assuming initial stock

    // Prepare chart options
    const options = {
        chart: {
            type: 'area',
        },
        xaxis: {
            categories: categories,
        },
    };

    // Return options and series
    return {
        options,
        series: [
            {
                name: 'Outgoing',
                data: outgoingData,
            },
            {
                name: 'Incoming',
                data: incomingData,
            },
        ],
    };
};