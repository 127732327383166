import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, Modal, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  getProductsSearchReducer,
} from "../../store/inventoryReducer";
import { PlusCircleOutlined } from "@ant-design/icons";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  createIncomingProduct,
  fetchIncomingLists,
  fetchSingleIncomingList,
  confirmIncomingList,
  confirmImportAdminIncomingList,
} from "../../store/incomingListReducer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TableA from "../../components/tables/table";
import TextArea from "antd/lib/input/TextArea";

const Incominginventory = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [quantityData, setQuantityData] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [productId, setProductId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [productQuery] = useState("");
  const { inventories } = useSelector((state) => state.inventory);
  const [activeTab, setActiveTab] = useState("draft");
  const [ setHandleModal] = useState("");
  const [openIncomingModal, setOpenIncomingModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [confirmModal,setConfirmModal] = useState(false);
  const { incomingList } = useSelector((state) => state.incoming);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleIncomingList(id));
    dispatch(getActiveWarehouseReducer());
    setQuantityData([]);
  }, [crdentialDataForOne?.warehouse,id,dispatch]);

  const handleProductQuery = (event) => {
    dispatch(
      getProductsSearchReducer({
        query: event.target.value,
      })
    );
  };


  const Image_url = "https://ethiodererp.com/images/";

  const addNewIncomingList = async () => {
    try {
      // Transform quantityData into an array of objects
      const arr = Object.entries(quantityData).map(([key, value]) => ({
        name: key,
        value,
      }));
  
      // Dispatch the createIncomingProduct action and wait for it to complete
      const resultAction = await dispatch(
        createIncomingProduct({
          list_id: id,
          product: productId,
          quantity: arr,
        })
      );
  
      // Check if the action was fulfilled (successful)
      if (createIncomingProduct.fulfilled.match(resultAction)) {
        // Fetch the updated incoming lists after successfully creating the product
        await dispatch(fetchIncomingLists());
        
        // Close the modal and set the active tab
        setOpenIncomingModal(false);
        setActiveTab("draft");
      } else {
        console.error('Failed to create incoming product:', resultAction.payload);
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error('Error adding incoming list:', error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };

  const handleRemarks = (event) => {
    setRemarks(event.target.value);
  };
  const confirmImportAdmin = async()=>{
    try {
      // Dispatch the confirmIncomingList action and wait for it to complete
      const resultAction = await dispatch(
        confirmImportAdminIncomingList({
          list_id: id,
          remark: remarks,
        })
      );
  
      // Check if the action was fulfilled (successful)
      if (confirmImportAdminIncomingList.fulfilled.match(resultAction)) {
        // Fetch the updated incoming lists after confirming the list
        await dispatch(fetchIncomingLists());
        await  dispatch(fetchSingleIncomingList(id));
        // Close the modal and set the active tab
        setApproveModal(false);
        setActiveTab("draft");
      } else {
        console.error('Failed to approve the incoming list:', resultAction.payload);
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error('Error approving incoming list:', error);
      // Handle any additional errors here (e.g., show an error message)
    }
  }
  const handlePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
    setPreviewOpen(true);
  };

  const approveIncomingList = async () => {
    try {
      // Dispatch the confirmIncomingList action and wait for it to complete
      const resultAction = await dispatch(
        confirmIncomingList({
          list_id: id,
          remark: remarks,
        })
      );
  
      // Check if the action was fulfilled (successful)
      if (confirmIncomingList.fulfilled.match(resultAction)) {
        // Fetch the updated incoming lists after confirming the list
        await dispatch(fetchIncomingLists());
        await  dispatch(fetchSingleIncomingList(id));
        // Close the modal and set the active tab
        setApproveModal(false);
        setActiveTab("draft");
      } else {
        console.error('Failed to approve the incoming list:', resultAction.payload);
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error('Error approving incoming list:', error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };

  const displayModal = (record, handleModal) => {
    setHandleModal(handleModal);
    setProductId(record?._id);
    setOpenIncomingModal(true);
    setProductQuantity(record?.quantity);
  };

  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };

  const columnsA = [
    {
      title: "Product Name",
      render: (text, record) => <span>{record.name}</span>,
    },

    {
      title: "Quantity",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {record.quantity.map((item) => (
            <li>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <span>
          <Button
            onClick={() => displayModal(record, "OutgoingInventory")}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  const columnsB = [
    {
      title: "Product Name",
      render: (text, record) => <span>{record.product?.name}</span>,
    },
    {
      title: "Product Code",
      render: (text, record) => <span>{record.product?.code}</span>,
    },
    {
      title: "Product Image",
      render: (text, record) => (
        <span>
          {record.product?.image_extension ? (
            <Image
              width={100}
              src={Image_url + record.product?._id + record.product?.image_extension}
              onClick={() =>
                handlePreview(
                  Image_url +
                    record.product?._id +
                    record.product?.image_extension
                )
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            "Image unavailable"
          )}
        </span>
      ),
    },

    {
      title: "Quantity",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {record.quantity.map((item) => (
            <li>
              {item.value} : {item.name}
            </li>
          ))}
        </span>
      ),
    },
    
  ];

  return (
    <div className="ml-4">
      <div className="">
        {activeTab === "draft" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              justifyContent: "space-between",
            }}
          >
            <h2
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              GRN: {incomingList?.grn}
            </h2>
            <h2
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              Status: {incomingList?.status}
            </h2>
            {incomingList?.status === 'pending_import_admin_approval' && crdentialDataForOne?.role === 'importAdmin' ? <Button
              onClick={() => setConfirmModal(true)}
              color="green"
              style={{ backgroundColor: "#5856d6", color: "white" }}
            >
              Confirm
            </Button> : crdentialDataForOne?.role === 'importAdmin' ? 'Confirmed' : ''}
            {incomingList?.status !== 'approved' && crdentialDataForOne?.role === 'finance' ? <Button
              onClick={() => setApproveModal(true)}
              color="green"
              style={{ backgroundColor: "#5856d6", color: "white" }}
            >
              Approve
            </Button> : crdentialDataForOne?.role === 'finance' ? 'Approved' : ''}
          </div>
        ) : (
          ""
        )}
      </div>

      <Modal
        centered
        onOk={approveIncomingList}
        onCancel={() => setApproveModal(false)}
        title="Add Remarks"
        visible={approveModal}
      >
        <Form>
          <Form.Item
          labelAlign="vertical"
          >
            <label style={{marginBottom:"10px"}}>Remarks</label>
            <TextArea
              onChange={handleRemarks}
              rows={8}
              name="remarks"
              value={remarks}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        onOk={confirmImportAdmin}
        onCancel={() => setConfirmModal(false)}
        title="Add Remarks"
        visible={confirmModal}
      >
        <Form>
          <Form.Item
          labelAlign="vertical"
          >
            <label style={{marginBottom:"10px"}}>Remarks</label>
            <TextArea
              onChange={handleRemarks}
              rows={8}
              name="remarks"
              value={remarks}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        centered
        onOk={addNewIncomingList}
        onCancel={() => setOpenIncomingModal(false)}
        title="New Incoming Lists"
        visible={openIncomingModal}
      >
        <Row gutter={5}>
          <Col xs={24} lg={24}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                width: 300,
              }}
            >
              {productQuantity?.map((item) => (
                <Form.Item
                  className="capitalize"
                  style={{ textTransform: "capitalize" }}
                  name={item}
                  label={item}
                >
                  <Input
                    name={item}
                    value={item}
                    onChange={handleQuantityData}
                  />
                </Form.Item>
              ))}
              {/* <Form.Item
                className="capitalize"
                name="displayName"
                label="Remarks"
              >
                <Input
                  type="text"
                  name={displayName}
                  value={displayName}
                  onChange={handleDisplayName}
                />
              </Form.Item> */}
              {/* <Form.Item className="capitalize" name="remarks" label="Remarks">
                <Input
                  type="text"
                  name={remarks}
                  value={remarks}
                  onChange={handleRemarks}
                />
              </Form.Item> */}
            </Form>
          </Col>
        </Row>
      </Modal>

      {activeTab === "draft" ? (
        <div>
          <div className="table">
            <TableA columnnn={columnsB} dataaa={incomingList?.inventory_list} />
          </div>
        </div>
      ) : (
        <div>
          <h2 className="text-xl mb-2">Incoming Inventory</h2>
          <Row gutter={20}>
            <Col xs={24} sm={24} lg={8}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item name="productname" label="Product Name">
                  <Input
                    value={productQuery}
                    name="productQuery"
                    onChange={handleProductQuery}
                    placeholder="Search by Product"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <div>
            <TableA columnnn={columnsA} dataaa={inventories} />
          </div>
        </div>
      )}
    </div>
  );
};
export default Incominginventory;
