import React, { useState } from "react";
import { Input } from "antd";
import { getActiveProductReducer, getProductsSearchList } from "../../store/productReducer"; // Assuming this is your API call function
import { useDispatch } from "react-redux";

const ProductSearch = () => {
  const [query, setQuery] = useState("");
const dispatch = useDispatch()
  // Debounced search function wrapped with useCallback
//   const debouncedSearch = useMemo(
//     debounce((value) => {
//       dispatch(getProductsSearchList({
//         query: value,
//       }))
//     }, 300), // 300ms debounce time
//     [] // Empty dependency array means the debounced function won't change
//   );

  // Handle input change
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    if(value){
       dispatch(getProductsSearchList({
        query: value,
      })) 
    }else{
        dispatch(getActiveProductReducer())
    }
     // Call the debounced function with the updated input value
  };



  return (
    <div>
      {/* Search Input */}
      <Input
        placeholder="Search products..."
        value={query}
        onChange={handleSearchChange}
        style={{ marginBottom: "20px", width: "300px" }}
      />
    </div>
  );
};

export default ProductSearch;