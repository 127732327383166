import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getOutgoingLists,
  addNewOutgoingLists,
  getSingleOutgoingLists,
  addOutgoingProduct,
  submitOutgoingList,
  approveOutgoingList,
  confirmDelivery,
  confirmSuperVisorDelivery,
  deleteOutgoingProductList,
  updateOutgoingProductList,
} from "../services/incomingLists";
import { toast } from "react-toastify";

const initialState = {
  isLoading: false,
  error: null,
  isError: false,
  isSuccess: false,
  message: "",
  outgoingList: {},
  outgoingLists: [],
};
export const fetchOutgoingLists = createAsyncThunk(
  "outgoing/fetch",
  async (thunkAPI) => {
    try {
      return await getOutgoingLists();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmDeliveryProducts = createAsyncThunk(
  "outgoing/delivery",
  async (payload, thunkAPI) => {
    try {
      return await confirmDelivery(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmSupervisorLists = createAsyncThunk(
  "outgoing/confirm/supervisor-lists",
  async (payload, thunkAPI) => {
    try {
      return await confirmSuperVisorDelivery(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createOutgoingList = createAsyncThunk(
  "outgoing/create",
  async (payload, thunkAPI) => {
    try {
      return await addNewOutgoingLists(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createOutgoingProduct = createAsyncThunk(
  "outgoing/product/add",
  async (payload, thunkAPI) => {
    try {
      console.log(payload, "inside reducer");
      return await addOutgoingProduct(payload);
    } catch (error) {
      console.log(error, "error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const saveOutGoingList = createAsyncThunk(
  "outgoing/product/submit",
  async (payload, thunkAPI) => {
    try {
      return await submitOutgoingList(payload);
    } catch (error) {
      console.log(error, "error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const confirmOutgoingList = createAsyncThunk(
  "outgoing/product/approve",
  async (payload, thunkAPI) => {
    try {
      return await approveOutgoingList(payload);
    } catch (error) {
      console.log(error, "error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const removeOutgoingProductList = createAsyncThunk(
  "outgoing/product/remove-outgoing-product-list",
  async (payload, thunkAPI) => {
    try {
      return await deleteOutgoingProductList(payload);
    } catch (error) {
      console.log(error, "error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOutgoingProductListReducer = createAsyncThunk(
  "outgoing/product/update-outgoing-product-list",
  async (payload, thunkAPI) => {
    try {
      return await updateOutgoingProductList(payload);
    } catch (error) {
      console.log(error, "error creating product");
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const fetchSingleOutgoingList = createAsyncThunk(
  "outgoing/single",
  async (id, thunkAPI) => {
    try {
      return await getSingleOutgoingLists(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      // console.log(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const outgoingListSlice = createSlice({
  name: "outgoingList",
  initialState,
  reducers: {
    getAllDevices: (state) => {},
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOutgoingLists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOutgoingLists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        console.log(action.payload?.data?.outgoing_list_all, "inside reducer");
        state.outgoingLists = action.payload?.data?.outgoing_list_all;
      })
      .addCase(fetchOutgoingLists.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(createOutgoingList.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(createOutgoingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Outgoing List Created Successfully");
      })
      .addCase(createOutgoingList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(fetchSingleOutgoingList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchSingleOutgoingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.outgoingList = action.payload?.data?.outgoing_list;
        state.message = action.payload?.message;
      })
      .addCase(fetchSingleOutgoingList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(createOutgoingProduct.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createOutgoingProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Product Added Successfully");
      })
      .addCase(createOutgoingProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(saveOutGoingList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveOutGoingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Outgoing List Submitted Successfully");
      })
      .addCase(saveOutGoingList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(confirmOutgoingList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(confirmOutgoingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Outgoing List Approved Successfully");
      })
      .addCase(confirmOutgoingList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(confirmDeliveryProducts.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(confirmDeliveryProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Delivery Confirmed Successfully");
      })
      .addCase(confirmDeliveryProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(confirmSupervisorLists.pending, (state, action) => {
        state.isLoading = false;
      })
      .addCase(confirmSupervisorLists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Supervisor List Confirmed Successfully");
      })
      .addCase(confirmSupervisorLists.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(removeOutgoingProductList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(removeOutgoingProductList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Product Removed Successfully");
      })
      .addCase(removeOutgoingProductList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateOutgoingProductListReducer.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateOutgoingProductListReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.message = action.payload;
        toast.success("Product Updated Successfully");
      })
      .addCase(updateOutgoingProductListReducer.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { getAllDevices } = outgoingListSlice.actions;
export const isLoading = (state) => state.outgoing.isLoading;
export const isError = (state) => state.outgoing.isError;
export const isSuccess = (state) => state.outgoing.isSuccess;
export const message = (state) => state.outgoing.message;
export const outgoingLists = (state) => state.outgoing.outgoingLists;
export const outgoingList = (state) => state.outgoing.outgoingList;

export default outgoingListSlice.reducer;
