import React, { useEffect, useState } from "react";
import { Button, Row, Col, Form, Input, Modal, Image, Popconfirm } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getProductsSearchReducer } from "../../store/inventoryReducer";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  fetchSingleOutgoingList,
  confirmOutgoingList,
  confirmDeliveryProducts,
  saveOutGoingList,
  createOutgoingProduct,
  fetchOutgoingLists,
  confirmSupervisorLists,
  updateOutgoingProductListReducer,
  removeOutgoingProductList,
} from "../../store/outgoingListReducers";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TableA from "../../components/tables/table";
import GeneratePdf from "../../components/base/generatePdf";
import TextArea from "antd/lib/input/TextArea";
import { ConvertDate } from "../../utils/moment";

const Incominginventory = () => {
  const [form] = Form.useForm();
  const onFinish = (values) => {
    //
  };
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [quantityData, setQuantityData] = useState([]);
  const [productQuantity, setProductQuantity] = useState([]);
  const [productId, setProductId] = useState("");
  const [remarks, setRemarks] = useState("");
  const [productQuery] = useState("");
  const { inventories,isLoading } = useSelector((state) => state.inventory);
  const [activeTab, setActiveTab] = useState("draft");
  const [openIncomingModal, setOpenIncomingModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const { outgoingList } = useSelector((state) => state.outgoing);
  const { crdentialDataForOne } = useSelector((state) => state.login);
  const [submitModal, setSubmitModal] = useState(false);
  const [supervisorModal, setSupervisorModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [quantityFields, setQuantityFields] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);

  const { id } = useParams();

  const formatName = (firstName, middleName) => {
    if (firstName && middleName) {
      return firstName + " " + middleName;
    } else {
      return "";
    }
  };

  const items = outgoingList?.inventory_list;
  const customer = outgoingList?.customer?.name;
  const checkedBy = formatName(
    outgoingList?.verified_by?.first_name,
    outgoingList?.verified_by?.last_name
  );
  const preparedBy = formatName(
    outgoingList?.created_by?.first_name,
    outgoingList?.created_by?.last_name
  );
  const approvedBy = formatName(
    outgoingList?.outgoing_delivery_overseen_by?.first_name,
    outgoingList?.outgoing_delivery_overseen_by?.last_name
  );
  const deliveredAt = ConvertDate(outgoingList?.outgoing_delivered_on);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSingleOutgoingList(id));
    dispatch(getActiveWarehouseReducer());
    setQuantityData([]);
    // setWarhousename(crdentialDataForOne?.warehouse);
  }, [id, crdentialDataForOne, dispatch]);

  const handleProductQuery = (event) => {
    dispatch(
      getProductsSearchReducer({
        query: event.target.value,
      })
    );
  };

  const displayIncomingModal = () => {
    setActiveTab(activeTab === "draft" ? "received" : "draft");
  };
  const Image_url = "https://ethiodererp.com/images/";

  const handleRemarks = (event) => {
    setRemarks(event.target.value);
  };
  const handlePreview = (imageUrl) => {
    setPreviewImage(imageUrl);
    setPreviewOpen(true);
  };

  const approveOutgoingList = async () => {
    try {
      // Dispatch the confirmOutgoingList action and wait for it to complete
      const resultAction = await dispatch(
        confirmOutgoingList({
          list_id: id,
          remark: remarks,
        })
      );

      // Check if the action was fulfilled (successful)
      if (confirmOutgoingList.fulfilled.match(resultAction)) {
        // Fetch the updated single outgoing list after confirming
        await dispatch(fetchSingleOutgoingList(id));
        await dispatch(fetchOutgoingLists());
        setActiveTab("draft");
        // Optionally, you can reset any local state or show a success message here
      } else {
        console.error(
          "Failed to approve the outgoing list:",
          resultAction.payload
        );
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error approving outgoing list:", error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };

  const displayModal = (record) => {
    setProductId(record?._id);
    setOpenIncomingModal(true);
    setProductQuantity(record?.quantity);
  };

  const submitOutgoingList = async () => {
    try {
      // Dispatch the saveOutGoingList action and wait for it to complete
      const resultAction = await dispatch(
        saveOutGoingList({
          list_id: id,
          remark: remarks,
        })
      );

      // Check if the action was fulfilled (successful)
      if (saveOutGoingList.fulfilled.match(resultAction)) {
        // Fetch the updated single outgoing list after saving
        await dispatch(fetchSingleOutgoingList(id));

        // Fetch the updated outgoing lists
        await dispatch(fetchOutgoingLists());

        // Set the active tab to 'draft'
        setActiveTab("draft");
      } else {
        console.error(
          "Failed to save the outgoing list:",
          resultAction.payload
        );
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error saving outgoing list:", error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };
  const addNewOutgoingProductList = async () => {
    try {
      // Transform quantityData into an array of objects
      const arr = Object.entries(quantityData).map(([key, value]) => ({
        name: key,
        value,
      }));

      // Dispatch the createOutgoingProduct action and wait for it to complete
      const resultAction = await dispatch(
        createOutgoingProduct({
          list_id: id,
          product: productId,
          quantity: arr,
        })
      );

      // Check if the action was fulfilled (successful)
      if (createOutgoingProduct.fulfilled.match(resultAction)) {
        // Fetch the updated outgoing lists after successfully creating the product
        dispatch(fetchSingleOutgoingList(id));

        // Close the modal and set the active tab
        setOpenIncomingModal(false);
        setActiveTab("draft");
      } else {
        console.error(
          "Failed to create outgoing product:",
          resultAction.payload
        );
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error adding outgoing product list:", error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };

  const confirmDeliveryOutgoing = async () => {
    try {
      // Dispatch the confirmDeliveryProducts action and wait for it to complete
      const resultAction = await dispatch(
        confirmDeliveryProducts({
          list_id: id,
          remark: remarks,
        })
      );

      // Check if the action was fulfilled (successful)
      if (confirmDeliveryProducts.fulfilled.match(resultAction)) {
        // Fetch the updated outgoing lists after confirming the delivery
        await dispatch(fetchOutgoingLists());
        setActiveTab("draft");
        setConfirmModal(false);
        dispatch(fetchSingleOutgoingList(id));
        // Optionally, you can reset any local state or show a success message here
      } else {
        console.error(
          "Failed to confirm delivery of outgoing products:",
          resultAction.payload
        );
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error confirming delivery of outgoing products:", error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };

  const handleEditOutgoingProduct = (record) => {
    setProductId(record?.product?._id);
    setQuantityFields(record?.quantity);
    setEditModal(true);
  };

  const handleDeleteOutgoingProduct = async (record) => {
    try {
      const resultAction = dispatch(
      removeOutgoingProductList({
        list_id: id,
        product_id: record?.product?._id,
      })
    );

    if (removeOutgoingProductList.fulfilled.match(resultAction)) {
      // Fetch the updated outgoing lists after confirming the delivery
      await dispatch(fetchOutgoingLists());
      dispatch(fetchSingleOutgoingList(id));
      // Optionally, you can reset any local state or show a success message here
    } else {
      console.error(
        "Failed to confirm delivery of outgoing products:",
        resultAction.payload
      );
    }
    } catch (error) {
      console.log(error)
    }
    
  };

  const confirmSupervisorOutgoing = async () => {
    try {
      // Dispatch the confirmDeliveryProducts action and wait for it to complete
      const resultAction = await dispatch(
        confirmSupervisorLists({
          list_id: id,
          remark: remarks,
        })
      );

      // Check if the action was fulfilled (successful)
      if (confirmSupervisorLists.fulfilled.match(resultAction)) {
        // Fetch the updated outgoing lists after confirming the delivery
        await dispatch(fetchOutgoingLists());
        setActiveTab("draft");
        setConfirmModal(false);
        dispatch(fetchSingleOutgoingList(id));
        // Optionally, you can reset any local state or show a success message here
      } else {
        console.error(
          "Failed to confirm delivery of outgoing products:",
          resultAction.payload
        );
        // Handle the error (e.g., show an error message to the user)
      }
    } catch (error) {
      console.error("Error confirming delivery of outgoing products:", error);
      // Handle any additional errors here (e.g., show an error message)
    }
  };
  const editOutgoingProduct = async () => {

    try {
      const resultAction = dispatch(
      updateOutgoingProductListReducer({
        list_id: id,
        product_id: productId,
        quantity: quantityFields,
      })
    );
    if (updateOutgoingProductListReducer.fulfilled.match(resultAction)) {
      // Fetch the updated outgoing lists after confirming the delivery
      await dispatch(fetchOutgoingLists());
      dispatch(fetchSingleOutgoingList(id));
      // Optionally, you can reset any local state or show a success message here
    } else {
      console.error(
        "Failed to confirm delivery of outgoing products:",
        resultAction.payload
      );
      // Handle the error (e.g., show an error message to the user)
    }
    } catch (error) {
      console.log(error)
    }

    
  };

  const handleQuantityData = (evnt) => {
    evnt.persist();
    setQuantityData({
      ...quantityData,
      [evnt.target.name]: evnt.target.value,
    });
  };
  const handleEditQuantityData = (event) => {
    const { name, value } = event.target;

    // Update the quantityFields state immutably
    setQuantityFields((prevFields) =>
      prevFields.map((field) =>
        field.name === name ? { ...field, value } : field
      )
    );

    // Update the form's field value
    form.setFieldsValue({
      [name]: value,
    });
  };

  const columnsA = [
    {
      title: "Product Name",
      render: (text, record) => <span>{record.name}</span>,
    },
    {
      title: "Product Image",
      render: (text, record) => (
        <span>
          {record.image_extension ? (
            <Image
              width={100}
              src={Image_url + record._id + record.image_extension}
              onClick={() =>
                handlePreview(Image_url + record._id + record.image_extension)
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            "Image unavailable"
          )}
        </span>
      ),
    },

    {
      title: "Quantity",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {record.quantity.map((item) => (
            <li>{item}</li>
          ))}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <span>
          <Button
            onClick={() => displayModal(record, "OutgoingInventory")}
            className="mr-2"
            icon={<PlusCircleOutlined />}
          ></Button>
        </span>
      ),
    },
  ];

  const columnsB = [
    {
      title: "Product Name",
      render: (text, record) => <span>{record.product.name}</span>,
    },
    {
      title: "Product Code",
      render: (text, record) => <span>{record.product?.code}</span>,
    },
    {
      title: "Product Image",
      render: (text, record) => (
        <span>
          {record.product.image_extension ? (
            <Image
              width={100}
              src={
                Image_url + record.product._id + record.product.image_extension
              }
              onClick={() =>
                handlePreview(
                  Image_url +
                    record.product._id +
                    record.product.image_extension
                )
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            "Image unavailable"
          )}
        </span>
      ),
    },

    {
      title: "Quantity",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {record.quantity.map((item) => (
            <li>
              {item.value} : {item.name}
            </li>
          ))}
        </span>
      ),
    },
  ];

  const columnsBForAdmin = [
    {
      title: "Product Name",
      render: (text, record) => <span>{record.product.name}</span>,
    },
    {
      title: "Product Code",
      render: (text, record) => <span>{record.product?.code}</span>,
    },
    {
      title: "Product Image",
      render: (text, record) => (
        <span>
          {record.product.image_extension ? (
            <Image
              width={100}
              src={
                Image_url + record.product._id + record.product.image_extension
              }
              onClick={() =>
                handlePreview(
                  Image_url +
                    record.product._id +
                    record.product.image_extension
                )
              }
              style={{ cursor: "pointer" }}
              preview={{
                visible: previewOpen,
                src: previewImage,
                onVisibleChange: (visible) => setPreviewOpen(visible),
              }}
            />
          ) : (
            "Image unavailable"
          )}
        </span>
      ),
    },

    {
      title: "Quantity",
      render: (text, record) => (
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
          }}
        >
          {record.quantity.map((item) => (
            <li>
              {item.value} : {item.name}
            </li>
          ))}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <span>
          <Button
            onClick={() => handleEditOutgoingProduct(record)}
            className="mr-2"
            icon={<EditOutlined />}
          ></Button>
          <Popconfirm
            title="Are you sure you want to delete this item?"
            onConfirm={() => handleDeleteOutgoingProduct(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button icon={<DeleteOutlined />} />
          </Popconfirm>
        </span>
      ),
    },
  ];

  return (
    <div className="ml-4">
      <div className="">
        {activeTab === "draft" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              justifyContent: "space-between",
            }}
          >
            <h2
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              GRN: {outgoingList?.gin}
            </h2>
            <h2
              style={{
                display: "flex",
                flexDirection: "column",
                fontSize: "16px",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              Status: {outgoingList?.status}
            </h2>
            {crdentialDataForOne?.role === "warehouseAdmin" &&
            outgoingList?.status === "approved" &&
            outgoingList?.status !== "delivered" ? (
              <Button
                style={{ backgroundColor: "#5856d6", color: "white" }}
                onClick={() => setConfirmModal(true)}
              >
                Confirm Delivery{" "}
              </Button>
            ) : (
              ""
            )}
            {crdentialDataForOne?.role === "salesSupervisor" &&
            outgoingList?.status === "pending_sales_supervisor_approval" ? (
              <Button
                style={{ backgroundColor: "#5856d6", color: "white" }}
                onClick={() => setSupervisorModal(true)}
              >
                Confirm Outgoing{" "}
              </Button>
            ) : (
              ""
            )}
            {crdentialDataForOne?.role === "finance" &&
            outgoingList?.status !== "draft" &&
            outgoingList?.status !== "approved" &&
            outgoingList?.status !== "delivered" ? (
              <Button
                style={{ backgroundColor: "#5856d6", color: "white" }}
                onClick={() => setApproveModal(true)}
              >
                Approve Outgoing{" "}
              </Button>
            ) : (
              ""
            )}
            {crdentialDataForOne?.role === "warehouseAdmin" &&
            outgoingList?.status === "delivered" ? (
              <GeneratePdf
                date={deliveredAt}
                TIN="0001519203"
                regInfo="14/670/41001/2004"
                items={items}
                bankDetails=""
                preparedBy={preparedBy}
                checkedBy={checkedBy}
                approvedBy={approvedBy}
                deliveryTo={customer}
                grandTotal=""
              />
            ) : (
              ""
            )}

            {crdentialDataForOne?.role === "sales" &&
            outgoingList?.status === "draft" ? (
              <>
                {" "}
                <Button
                  onClick={() => setSubmitModal(true)}
                  color="green"
                  style={{ backgroundColor: "#5856d6", color: "white" }}
                >
                  Submit Outgoing
                </Button>
                <Button
                  style={{ backgroundColor: "#5856d6", color: "white" }}
                  onClick={displayIncomingModal}
                >
                  Add Product{" "}
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <Modal
        centered
        onOk={approveOutgoingList}
        onCancel={() => setApproveModal(false)}
        title="Add Remarks"
        visible={approveModal}
      >
        <Form>
          <Form.Item labelAlign="vertical">
            <label style={{ marginBottom: "10px" }}>Remarks</label>
            <TextArea
              onChange={handleRemarks}
              rows={8}
              name="remarks"
              value={remarks}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        onOk={editOutgoingProduct}
        onCancel={() => setEditModal(false)}
        title="Edit Outgoing List"
        visible={editModal}
      >
        <Row gutter={5}>
          <Col xs={24} lg={24}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                width: 300,
              }}
            >
              {quantityFields?.map((item) => (
                <Form.Item
                  className="capitalize"
                  style={{ textTransform: "capitalize" }}
                  label={item.name}
                >
                  <Input
                    placeholder={item.value}
                    key={item._id}
                    name={item.name}
                    value={item.value}
                    onChange={handleEditQuantityData}
                  />
                </Form.Item>
              ))}
            </Form>
          </Col>
        </Row>
      </Modal>

      <Modal
        centered
        onOk={confirmSupervisorOutgoing}
        onCancel={() => setSupervisorModal(false)}
        title="Add Remarks"
        visible={supervisorModal}
      >
        <Form>
          <Form.Item labelAlign="vertical">
            <label style={{ marginBottom: "10px" }}>Remarks</label>
            <TextArea
              onChange={handleRemarks}
              rows={8}
              name="remarks"
              value={remarks}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        onOk={confirmDeliveryOutgoing}
        onCancel={() => setConfirmModal(false)}
        title="Add Remarks"
        visible={confirmModal}
      >
        <Form>
          <Form.Item labelAlign="vertical">
            <label style={{ marginBottom: "10px" }}>Remarks</label>
            <TextArea
              onChange={handleRemarks}
              rows={8}
              name="remarks"
              value={remarks}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        onOk={submitOutgoingList}
        onCancel={() => setSubmitModal(false)}
        title="Add Remarks"
        visible={submitModal}
      >
        <Form>
          <Form.Item labelAlign="vertical">
            <label style={{ marginBottom: "10px" }}>Remarks</label>
            <TextArea
              onChange={handleRemarks}
              rows={8}
              name="remarks"
              value={remarks}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        centered
        onOk={addNewOutgoingProductList}
        onCancel={() => setOpenIncomingModal(false)}
        title="New Incoming Lists"
        visible={openIncomingModal}
      >
        <Row gutter={5}>
          <Col xs={24} lg={24}>
            <Form
              layout="vertical"
              form={form}
              name="control-hooks"
              onFinish={onFinish}
              style={{
                width: 300,
              }}
            >
              {productQuantity?.map((item) => (
                <Form.Item
                  className="capitalize"
                  style={{ textTransform: "capitalize" }}
                  name={item}
                  label={item}
                >
                  <Input
                    name={item}
                    value={item}
                    onChange={handleQuantityData}
                  />
                </Form.Item>
              ))}
            </Form>
          </Col>
        </Row>
      </Modal>
      {activeTab === "draft" ? (
        <div>
          <div className="table">
            <TableA
              columnnn={
                crdentialDataForOne?.role === "sales" &&
                outgoingList?.status === "draft"
                  ? columnsBForAdmin
                  : columnsB
              }
              dataaa={outgoingList?.inventory_list}
            />
          </div>
        </div>
      ) : (
        <div>
          <h2 className="text-xl mb-2">Outgoing Product List</h2>
          <Row gutter={20}>
            <Col xs={24} sm={24} lg={8}>
              <Form
                layout="vertical"
                form={form}
                name="control-hooks"
                onFinish={onFinish}
              >
                <Form.Item name="productname" label="Product Name">
                  <Input
                    value={productQuery}
                    name="productQuery"
                    onChange={handleProductQuery}
                    placeholder="Search by Product"
                  />
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <div>
            <TableA isLoadinggg={isLoading} columnnn={columnsA} dataaa={inventories} />
          </div>
        </div>
      )}
    </div>
  );
};
export default Incominginventory;
