import React, { useEffect, useState } from "react";
import { Button, Col, Row, Form, Input, Select } from "antd";

import { useParams } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductReducer,
  updateProductReducer,
} from "../../store/productReducer";
import { getCategoryReducerForActive } from "../../store/categoryReducers";

const { Option } = Select;

const App = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [fields, setFields] = useState([]);
  const [qauntityFields, setQauntityFields] = useState([]);
  useEffect(() => {
    dispatch(getCategoryReducerForActive());
  }, [dispatch]);

  const { categories } = useSelector((state) => state.category);
  const { product } = useSelector((state) => state.product);

  const [productData, setProductData] = useState();
  const [category, setCategory] = useState("");
  const [qauntity, setQauntity] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [description, SetDescrpition] = useState("");
  const [bufferStock, setBufferStock] = useState("");
  const { id } = useParams();

  useEffect(() => {
    setTimeout(() => {
      if (id) {
        const selectedCategory = categories.find(
          (item) => item._id === product?.payload?.category?._id
        );
        const specficationsKeyAndValue = selectedCategory.specifications.map(
          (spec) => ({ name: spec, value: "" })
        );
        // setFields(selectedCategory.specifications);
        const result = specficationsKeyAndValue?.map((key) => {
          // Find the matching key in the data array
          const found = product?.payload?.specifications?.find(
            (item) => item.name === key.name
          );
          if (found) {
            return { name: key.name, value: found.value };
          } else {
            return { name: key.name, value: key.value };
          }
        });
        let updatedQuantity = [
          ...product?.payload?.quantity,
          ...selectedCategory.quantity_measurement,
        ];
        updatedQuantity = [...new Set(updatedQuantity)];
        // setQauntityFields(product?.payload?.quantity);
        setQauntityFields(updatedQuantity);
        setName(product?.payload?.name);
        setCode(product?.payload?.code);
        setCategory(product?.payload?.category?.data);
        setQauntity(product?.payload?.quantity);
        SetDescrpition(product?.payload?.description);

        setFields(result);
        setProductData({ ...product.payload, specifications: result });

        setCategory(product?.payload?.category?._id);
        setBufferStock(product?.payload?.buffer_stock?.quantity);
      }
    }, 400);
  }, [id, product, categories]);

  const handleProductSubmit = async () => {
    if (!productData) {
      return;
    }
    dispatch(
      addProductReducer({
        name,
        category,
        code,
        description: description,
        quantity: qauntity,
        specifications: productData?.specifications,
        buffer_stock: {
          unit_of_measurement: qauntityFields[0],
          quantity: bufferStock,
        },
      })
    );
    setName("");
    setQauntity([]);
    setCategory([]);
    SetDescrpition("");
    setFields([]);
    setCode("");
    setBufferStock("");
  };

  const updateProductOnclick = async () => {
    // let arr = [];
    // if (productData) {
    //   arr = Object.entries(productData).map(([key, value]) => ({
    //     name: key,
    //     value,
    //   }));
    // }
    dispatch(updateProductReducer({ ...productData, id }));
    setName("");
    setQauntity([]);
    setCategory([]);
    SetDescrpition("");
    setBufferStock("");
    setFields([]);
    setCode("");
  };

  const handleDescrpition = (event) => {
    SetDescrpition(event.target.value);
  };
  const handleName = (event) => {
    setName(event.target.value);
  };
  const handleCode = (event) => {
    setCode(event.target.value);
  };

  const onCategoryChange = (value) => {
    setFields([]);
    const selectedCategory = categories.find((item) => item._id === value);
    const specficationsKeyAndValue = selectedCategory.specifications.map(
      (spec) => ({ name: spec, value: "" })
    );
    setFields(specficationsKeyAndValue);
    setQauntityFields(selectedCategory.quantity_measurement);
    setCategory(value);
    setQauntity([]);
    setName("");
    SetDescrpition("");
  };

  const handleQauntity = (value) => {
    if (id) {
      setProductData({
        ...productData,
        quantity: value,
      });
    }
    setQauntity(value);
  };

  const handleBufferStock = (value) => {
    setBufferStock(value.target.value);
  };

  const onReset = () => {
    form.resetFields();
  };
  const handleProductData = (event) => {
    console.log(fields)
    const updatedfields = fields.map((field) => {
      if (field.name === event.target.name)
        return { name: field.name, value: event.target.value };
      return field;
    });
    setFields(updatedfields);
    setProductData({
      ...productData,
      specifications: updatedfields,
    });
  };

  //
  return (
    <div className="ml-4">
      <h1>{id ? "Update" : "Add"} Product</h1>
      <Row gutter={25}>
        <Col span={8}>
          <Form layout="vertical" form={form} name="control-hooks">
            <Form.Item
              label="Category"
              rules={[
                {
                  required: true,
                  message: "Missing Product Category",
                },
              ]}
            >
              <Select
                placeholder="Select Category"
                value={category}
                name="category"
                onChange={onCategoryChange}
              >
                {categories?.map((item, key) => (
                  <Option key={key} value={item._id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Product Name"
              rules={[
                {
                  required: true,
                  message: "Missing Product Name",
                },
              ]}
            >
              <Input onChange={handleName} name="name" value={name} />
            </Form.Item>
            <Form.Item
              label="Product Code"
              rules={[
                {
                  required: true,
                  message: "Missing Product Code",
                },
              ]}
            >
              <Input onChange={handleCode} name="code" value={code} />
            </Form.Item>

            <Form.Item
              label="Unit of Measure"
              rules={[
                {
                  required: true,
                  message: "Missing Product Quantity Measurment",
                },
              ]}
            >
              <Select
                placeholder="Select quantity"
                name="qauntity"
                value={qauntity}
                onChange={handleQauntity}
                mode="multiple"
                allowClear
              >
                {qauntityFields.map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            </Form.Item>

            <label>Buffer Stock</label>
            <div
              className="flex flex-row"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <p style={{ marginRight: "3px" }}>{qauntityFields[0]}</p>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Missing Buffer Stock",
                  },
                ]}
              >
                <Input
                  onChange={handleBufferStock}
                  name="bufferStock"
                  value={bufferStock}
                  placeholder="Quantity"
                />
              </Form.Item>
            </div>
            <Form.Item
              //  name="pr_descripition"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Missing Product Quantity Measurement",
                },
              ]}
            >
              <TextArea
                onChange={handleDescrpition}
                name="description"
                value={description}
              />
            </Form.Item>
            <Form.Item
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "right",
                justifyItems: "right",
              }}
            >
              <Button
                className="mx-4 bg-danger"
                htmlType="button"
                style={{ marginRight: "10px" }}
                onClick={onReset}
              >
                Reset
              </Button>

              {/* <Upload onChange={handleFileChange}>
                <Button icon={<UploadOutlined />}>Upload Excel</Button>
              </Upload> */}

              <Button
                style={{ backgroundColor: "#5856d6", color: "white" }}
                htmlType="submit"
                onClick={id ? updateProductOnclick : handleProductSubmit}
              >
                {id ? "Update" : "Add New"} Product
              </Button>
              {/* <Button onClick={handleUpload}>Import Product</Button> */}
            </Form.Item>
          </Form>
        </Col>
        <Col span={16}>
          <Row gutter={5}>
            {id
              ? fields.map((item) => (
                  <Col span={12}>
                    <Form
                      layout="vertical"
                      form={form}
                      name="specifications"
                      style={{
                        width: 300,
                      }}
                    >
                      <Form.Item label={item.name}>
                        <Input
                          name={item.name}
                          value={item.value}
                          onChange={handleProductData}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                ))
              : ""}

            {id
              ? ""
              : fields.map((item) => (
                  <Col span={12}>
                    <Form
                      layout="vertical"
                      form={form}
                      name="specifications"
                      style={{
                        width: 300,
                      }}
                    >
                      <Form.Item name={item.name} label={item.name}>
                        <Input
                          name={item.name}
                          value={item.value}
                          onChange={handleProductData}
                        />
                      </Form.Item>
                    </Form>
                  </Col>
                ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default App;
