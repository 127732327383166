import React from 'react';
import Chart from 'react-apexcharts';

const AreaChart = ({chartData}) => {
    
    return (
        <Chart options={chartData.options} series={chartData.series} type="area" height={350} />
    );
};

export default AreaChart;