import { useDispatch, useSelector } from "react-redux";
import CustomTable from "../../components/tables/table";
import { useEffect, useState } from "react";
import { getCategoryReducerForActive } from "../../store/categoryReducers";
import { getActiveWarehouseReducer } from "../../store/warehouseReducer";
import {
  getAllTransactionReducer,
} from "../../store/transactionReducers";
import {
  assetValuationReportReducer,
} from "../../store/report";
import DownloadButton from "../../components/base/download";
// import { ConvertDate } from "../../utils/moment";
import { ConvertDate } from "../../utils/moment";
import { Row, Select, Col, Button } from "antd";
import { reportEndPoint } from "../../utils/staticValues";
const { Option } = Select;
const ProductReport = () => {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.category);
  const { warehouses } = useSelector((state) => state.warehouse);
  const { reports } = useSelector((state) => state.report);
  useEffect(() => {
    dispatch(getActiveWarehouseReducer());
    dispatch(getCategoryReducerForActive());
    dispatch(assetValuationReportReducer());
    dispatch(getAllTransactionReducer());
  }, [dispatch]);

  const [warehose, setWarehose] = useState("");
  const [category, setCategory] = useState("");



  const handleTransactionCategory = (event) => {
    setCategory(event);
  };
  const handleTransactionWarehouse = (event) => {
    setWarehose(event);
  };


  
  const get_current_value = (purchasedPrice, purchasedDate, depreciationRate) => {
    let currentDate = new Date();
    let purchased = new Date(purchasedDate)
    let timeDiff = currentDate.getTime() - purchased.getTime();
    let diffYears = Math.floor(timeDiff / (1000 * 3600 * 24 * 365));
    let depreciationYears = diffYears;
    let depreciationAmount = (purchasedPrice * depreciationRate) / 100;
  
    const current_value = purchasedPrice - depreciationAmount * depreciationYears;
  
    return current_value;
  };
  const handleSearchTransaction = () => {
    dispatch(
      assetValuationReportReducer({
        productCategoryId:category,
        warehouseId: warehose,
      })
    );
  };

  const columns = [
    {
      title: "Name",
      render: (text, record) => <span>{record.product?.name}</span>,
    },
    {
      title: "Description",
      width:"15%",
      render: (text, record) => <span>{record?.product?.description}</span>,
    },
    {
      title: "Warehouse",
      width:"15%",
      render: (text, record) => <span>{record?.warehouse?.name}</span>,
    },
    {
      title: "Purchased Date",
      width:'15%',
      render: (text, record) => (
        <span>{ConvertDate(record?.purchasedDate)}</span>
      ),
    },
    {
      title: "Purchased Price",
      render: (text, record) => <span>{record?.purchasedPrice}</span>,
    },
    {
      title: "Depreciation Rate",
      render: (text, record) => <span>{record?.purchasedPrice}</span>,
    },
    {
      title: "Current Value",
      render: (text, record) => <span>{get_current_value(record?.purchasedPrice,record?.purchasedDate,record?.depreciationRate)}</span>,
    },
    {
      title: "Quantity",
      render: (text, record) => <span>{record?.quantity[0]?.name}</span>,
    },
    {
      title: "Unit",
      render: (text, record) => <span>{record?.quantity[0]?.value}</span>,
    },
    {
      title: "Valuation",
      render: (text, record) => <span>{get_current_value(record?.purchasedPrice,record?.purchasedDate,record?.depreciationRate) * record?.quantity[0]?.value}</span>,
    },
  ];

  return (
    <div>
      <div className="mb-2">
        <Row gutter={20} className="pl-3">
          <Col xs={24} sm={24} lg={6}></Col>
          <Col xs={24} sm={24} lg={6} className="">
            {/* <div className="flex flex-row w-full">
              <Input
                type="date"
                value={startDate}
                onChange={handleTransactionSTartDate}
              />
            </div> */}
          </Col>
          <Col xs={24} sm={24} lg={6} className="">
            {/* <Select
              className="w-full"
              value={daily}
              onChange={handleTransactionTime}
              placeholder="Select Date "
              defaultValue="daily"
            >
              <option value="daily">Daily</option>
              <option value="range">Range</option>
            </Select> */}
          </Col>
        </Row>
        <Row
          gutter={20}
          className="pl-3"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Col xs={24} sm={24} lg={6}>
            <Select
              style={{ width: "100%" }}
              onChange={handleTransactionCategory}
              className="w-full "
              defaultValue={"All Category"}
            >
              <option value="">All Category</option>
              {categories?.map((item, key) => (
                <Option key={key} value={item._id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col xs={24} sm={24} lg={6}>
            <Select
              style={{ width: "100%" }}
              onChange={handleTransactionWarehouse}
              className="w-full "
              defaultValue={"All Warehouse"}
            >
              <option value="">All Warehouse</option>
              {warehouses?.map((item, key) => (
                <option key={key} value={item._id}>
                  {item.name}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={24} sm={24} lg={6}>
            <div>
              {/* <Input placeholder="Search by Product" /> */}
            </div>
          </Col>
          <Col xs={24} sm={24} lg={6}>
            <Button
              style={{ marginRight: "5px" }}
              className="flex flex-row w-full mr-4"
              onClick={handleSearchTransaction}
            >
              Filter Report
            </Button>
            <DownloadButton filter={
             { warehouseId: warehose,
              productCategoryId: category,}
            } endPoint={reportEndPoint+'asset/valuations/excel'} fileName='report'/>
          </Col>
        </Row>
      </div>
      <CustomTable columnnn={columns} dataaa={reports} />
    </div>
  );
};
export default ProductReport;
