import axios from "../plugins/axios";

export const assetValuationReport = async (payload) => {
  const response = await axios.post("/api/reports/asset/valuations", payload);
  return response;
};

export const assetValuationReportExcel = async (payload) => {
  const response = await axios.post(
    "/api/reports/asset/valuations/excel",
    payload
  );
  return response;
};
export const outgoingRankingReport = async (payload) => {
  const response = await axios.get("/api/stats/rank/products/outgoing/numbers", payload);
  return response;
};
export const rankingProductsReport = async (payload) => {
  const response = await axios.post("/api/stats/rank/products", payload);
  return response;
};
export const customerRankingFrequency = async (payload) => {
  const response = await axios.get("/api/stats/rank/customers/frequency", payload);
  return response;
};
