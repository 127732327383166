import React from 'react';
import '../../assets/styles/tag.css';

const Tag = ({ text, color = 'white', background = 'gray', padding = '2px', borderRadius = '100%' }) => {
    const tagStyle = {
        color,
        backgroundColor: background,
        padding,
        borderRadius,
        display: 'inline-block',
        fontSize: '0.9em',
        width:'20px',
        textAlign: 'center',
        fontWeight:'bold',
        height:'20px',
        animation: 'blink 1s infinite', // Adding the blink animation
    };

    return <span style={tagStyle}>{text}</span>;
};

export default Tag;