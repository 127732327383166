import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createProduct,
  getProduct,
  updateProduct,
  deleteProduct,
  activateProduct,
  getActiveProduct,
  getRemovedProduct,
  getSearchProduct,
  addProductImage,
} from "../services/productAPI";
import { toast } from "react-toastify";
import { getProductSearch } from "../services/inventoryApi";
const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  product: {},
  products: [],
  productsByCategory: [],
  error: null,
};
export const addProductReducer = createAsyncThunk(
  "add/product",
  async (productdata, thunkApi) => {
    try {
      return await createProduct(productdata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getProductReducer = createAsyncThunk(
  "get/product",
  async (productdataa, thunkApi) => {
    try {
      console.log("productdataa");
      return await getProduct(productdataa);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const saveProductImage = createAsyncThunk(
  "save/product",
  async (payload, thunkApi) => {
    try {
      console.log("payload", payload);
      return await addProductImage(payload);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getRemovedProductReducer = createAsyncThunk(
  "getRemoved/product",
  async (productdataa, thunkApi) => {
    try {
      return await getRemovedProduct(productdataa);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getActiveProductReducer = createAsyncThunk(
  "active/product",
  async (productdataa, thunkApi) => {
    try {
      return await getActiveProduct(productdataa);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getProductsSearchList = createAsyncThunk(
  "products/search",
  async (inventoryData, thunkApi) => {
    try {
      const res = await getProductSearch(inventoryData);
      return res;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getSearchProductReducer = createAsyncThunk(
  "search/product",
  async (productdataa, thunkApi) => {
    try {
      return await getSearchProduct(productdataa);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const updateProductReducer = createAsyncThunk(
  "update/product",
  async (prodata, thunkApi) => {
    try {
      return await updateProduct(prodata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const deleteProductReducer = createAsyncThunk(
  "delete/product",
  async (prodata, thunkApi) => {
    try {
      return await deleteProduct(prodata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const activateProductReducer = createAsyncThunk(
  "activate/product",
  async (prodata, thunkApi) => {
    try {
      return await activateProduct(prodata);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkApi.rejectWithValue(message);
    }
  }
);

const productSlice = createSlice({
  name: "product",
  initialState: initialState,
  reducers: {
    getProductById: (state, payload) => {
      state.product = payload;
    },

    getAllproductByCategory: (state) => {
      // const selectedCategory = state.products.find(
      //   (item) => item.category == categoryId
      // );
      // state.productsByCategory = selectedCategory;
    },
  },
  extraReducers: (para) => {
    para
      .addCase(addProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        toast.success("Product Added Successfully");
      })
      .addCase(addProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.payload;
        toast.success(action.payload);
      })
      .addCase(getProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
        console.log(state.products);
      })
      .addCase(getProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(getRemovedProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRemovedProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.products = action.payload;
        console.log(state.products);
      })
      .addCase(getRemovedProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(deleteProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        console.log("AAA", action.payload);
        // const recordProduct = action.payload;
        // state.products = state.products?.filter(
        //   (record) => record._id !== recordProduct.data?.product?._id
        // );
        toast.success("Product deleted successfully");
      })
      .addCase(deleteProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(activateProductReducer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activateProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;

        const recordPrdocut = action.payload;
        state.products = state.products?.filter(
          (record) => record._id !== recordPrdocut.data?.product?._id
        );
        toast.success("Product Activated successfully");
      })
      .addCase(activateProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(saveProductImage.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(saveProductImage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success("Product Image Added Successfully");
      })
      .addCase(saveProductImage.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(updateProductReducer.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateProductReducer.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        toast.success("Product Updated Successfully");
      })
      .addCase(updateProductReducer.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        toast.error(action.payload);
      })
      .addCase(getActiveProductReducer.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(getSearchProductReducer.fulfilled, (state, action) => {
        state.products = action.payload;
      })
      .addCase(getProductsSearchList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getProductsSearchList.fulfilled, (state, action) => {
        state.products = action.payload;
        state.isLoading = false;
      })
      .addCase(getProductsSearchList.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload);
      });
  },
});
export const isSuccess = (state) => state.product.isSuccess;
export const isError = (state) => state.product.isError;
export const productsByCategory = (state) => state.product.productsByCategory;
export const isLoading = (state) => state.product.isLoading;
export const { getAllproductByCategory } = productSlice.actions;
export const products = (state) => state.product.products;

export const { getProductById } = productSlice.actions;

export default productSlice.reducer;

//export const {} = productSlice.actions;
